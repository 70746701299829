.checkout-row > div:first-of-type {
    order: 1
}

.checkout-row > div:last-of-type {
    order: 2
}

@media (max-width: 768px) {
    .checkout-row > div:first-of-type {
        padding: 0;
        order: 2
    }
    
    .checkout-row > div:last-of-type {
        padding: 0;
        order: 1;
        margin-bottom: 1rem;
    }
}